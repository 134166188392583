<template>
  <div v-if="homeNav.length">
    <div class="navigator">
      <button
        v-for="item in homeNav"
        :key="item.id"
        class="btn"
        :class="{ active : item.active }"
        @click="changePageClick(item)"
      >
        <span class="page-number fs--small">0{{ item.id }}</span>
        <div class="divider" />
        <div class="dot" />
      </button>
    </div>
    <div class="mobile-navigator">
      <button class="btn-prev" :class="{ disabled : homeNav[0].active }" @click="moveSection('up')">
        <icon-arrow-right class="icon icon-prev" />
      </button>
      <div class="divider" />
      <button class="btn-next" :class="{ disabled : homeNav[homeNav.length - 1].active }" @click="moveSection('down')">
        <icon-arrow-right class="icon icon-next" />
      </button>
    </div>
  </div>
</template>

<script>
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'

export default {
  components: {
    IconArrowRight
  },
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      homeNav: []
    }
  },
  created () {
    this.calcHomeNav()
  },
  methods: {
    calcHomeNav () {
      this.sections.forEach((item, index) => {
        this.homeNav.push({ id: index + 1, active: false })
      })
      this.homeNav[0].active = true
    },
    changePageClick(page) {
      this.$emit('change-page', page.id)
    },
    changePageScroll (origin, destination) {
      this.homeNav[origin].active = false
      this.homeNav[destination].active = true
    },
    moveSection (direction) {
      this.$emit('move-section', direction)
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-navigator, .navigator {
    z-index: 9;
    display: flex;
    flex-direction: column;
  }
  .mobile-navigator {
    padding: 8px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(12px);
    border-radius: 4px;
    display: none;
    @include max-w-sm {
      display: flex;
    }
    .divider {
      height: 2px;
      background: $white;
      opacity: 1;
      margin: 6px 0;
    }
    .btn-prev, .btn-next {
      transition: .3s;
      &.disabled {
        opacity: .3;
      }
      .icon {
        width: 16px;
        height: 16px;
      }
    }
    .btn-prev {
      .icon-prev {
        transform: rotate(-90deg);
      }
    }
    .btn-next {
      .icon-next {
        transform: rotate(90deg);
      }
    }
  }
  .navigator {
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(24px);
    border-radius: $border-radius-base;
    padding: 16px;
    @include max-w-sm {
      display: none;
    }
    .btn {
      position: relative;
      margin-bottom: 20px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        .page-number {
          top: -2px;
          color: $dark;
        }
        .dot {
          min-width: 24px;
          height: 24px;
          &:before {
            width: 22px;
            height: 22px;;
            background: #ED97A1;

          }
        }
      }
      .page-number {
        position: absolute;
        left: 0;
        top: -6px;
        color: $gray;
      }
      .divider {
        margin: 0 4px 0 0;
      }
      .dot {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        min-width: 16px;
        height: 16px;
        transition: .6s;
        background: linear-gradient(220deg, rgba(253,146,113,1) 0%, rgba(211,58,95,1) 100%);

        &::before {
          position: absolute;
          content: '';
          width: 14px;
          height: 14px;;
          border-radius: 50%;
          background: #E8B9A4;
        }
      }
    }
  }
</style>