<template>
  <div class="wrapper-step">
    <div class="info-step">
      <span class="number">
        {{ step.id }}
      </span>
      <span class="step">
        шаг
      </span>
    </div>
    <div class="step-description">
      {{ step.description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroInfoStep',
  props: {
    step: {
      type: Object,
      required: true
    }
  }

}
</script>

<style lang="scss" scoped>
.wrapper-step {
  z-index: 1;
  transition: .5s;
  @include max-w-md {
    cursor: pointer;
  }
  &.active {
    z-index: 9999;
    .step-description {
      visibility: visible;
      opacity: 1;
      bottom: -100px;
      @include max-w-md {
        display: none;
      }
    }
    @include max-w-md {
      .info-step {
        transform: scale(1.5) !important;
        &::after {
          top: 0;
        }
      }
    }
  }
  .info-step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #000;
    background: linear-gradient(45deg, rgba(237,151,161,1) 0%, rgba(237,151,161,0.1989282773656338) 100%);
    box-shadow: $box-shadow-base;
    border-radius: 50%;
    transition: .5s;
    @include max-w-md {
      width: 70px;
      height: 70px;
    }
    @include max-w-xs {
      width: 34px;
      height: 34px;
    }
    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 12px);
      top: 50%;
      background: url('~@/assets/img/home/hero/angle.png') center / contain no-repeat;
      width: 24px;
      height: 20px;
      transition: 1s;
    }
    &::before {
      z-index: 2;
      content: '';
      position: absolute;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      background: linear-gradient(180deg, #F8F8F8 0%, #FFFFFF 100%);
      border-radius: 50%;
      @include max-w-md {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
      }
      @include max-w-xs {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
      }
    }
    .number {
      z-index: 3;
      font-weight: 900;
      line-height: .8;
      font-size: 48px;
      @include max-w-md {
        font-size: 32px;
      }
      @include max-w-xs {
        font-size: 16px;
      }
    }
    .step {
      z-index: 3;
      font-weight: 500;
      text-transform: uppercase;
      @include max-w-xs {
        display: none;
      }
    }
  }
  .step-description {
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    min-width: 200px;
    height: auto;
    position: absolute;
    padding: 20px;
    background: $white;
    box-shadow: $box-shadow-base;
    border-radius: $border-radius-large;
    transition: 1s;
    left: -80px;
    bottom: -50px;
    @include max-w-lg {
      max-width: 200px;
      min-width: 200px;
    }
    @include max-w-md {
      bottom: auto;
      top: 0;
    }
    @include max-w-xs {
      padding: 12px;
      min-width: 185px;
      max-width: 185px;
    }
  }
}
</style>
