<template>
  <div class="wrapper-preloader">
    <div class="preloader">
      <div class="container">
        <svg-logo class="logo" />
        <p class="gray-text link fs--small td--underline" @click="$emit('skip-preloader')">
          Перейти на главную <br> (при медленном соединении)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SvgLogo from '@/assets/img/logo-preloader.svg'

export default {
  name: 'PreLoader',
  components: {
    SvgLogo
  }
}
</script>

<style lang="scss" scoped>
.wrapper-preloader {
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #FFF6ED;
  .preloader {
    width: 110vw;
    background: url('~@/assets/img/bg-preloader.png') center / cover no-repeat;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slide 2s ease-in-out forwards;
    .container {
      position: relative;
      height: 100%;
      padding-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .link {
      position: absolute;
      bottom: 5%;
      right: 5vw;
      cursor: pointer;
      @include max-w-lg {
        right: 10vw;
      }
      br {
        @include min-w-xs {
          display: none;
        }
      }
    }
    .logo {
      z-index: 9;
      display: flex;
      @include max-w-xs {
        width: 60%;
      }
      path {
        &:nth-child(2) {
          animation: pathLineTwo 2s forwards;
          transform-origin: 40%;
        }
        &:nth-child(3) {
          transform-origin: 30%;
          animation: pathLineOne 2s forwards;
        }
      }
      circle {
        cx: 120px;
        cy: 82px;
        animation: circleMove 2s forwards;
      }
      .mouse {
        path {
          animation: mouseCursor 2s forwards;
          transform-origin: 50%;
        }
      }
    }
  }
}
@keyframes slide {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  20% {
    transform: translate3d(-10%, 0, 0);
  }
  40% {
    transform: translate3d(-5vw, 0, 0);
  }
  100% {
    transform: translate3d(-5vw, 0, 0);
  }
}
@keyframes pathLineOne {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    transform: scale(0.3);
    opacity: 0;
  }
  65% {
    transform: scale(0.38, 0.48) translate3d(-5%, 0, 0);
    opacity: 0.1;
  }
  75% {
    transform: scale(0.38, 0.48) translate3d(-5%, 0, 0);
    opacity: 0.1;
  }
  100% {

  }
}
@keyframes pathLineTwo {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  50% {
    transform: scale(0.6);
    opacity: 0;
  }
  65% {
    transform: scale(0.72);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.72);
    opacity: 0.3;
  }
  100% {

  }
}
@keyframes circleMove {
  0% {
    cx: 120px;
    cy: 82px;
    opacity: 0;
  }
  75% {
    cx: 120px;
    cy: 82px;
    opacity: 0;
  }
  95% {
    cx: 256px;
    cy: 96px;
  }
  100% {
    cx: 246px;
    cy: 94px;
    opacity: 1;
  }
}
@keyframes mouseCursor {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    transform: scale(1);
  }
  65% {
    transform: scale(0.25);
    opacity: 1;
  }
  70% {
    transform: scale(0.25);
    opacity: 1;
  }
  100% {
    transform: scale(0.35);
    opacity: 0;
  }
}
</style>