<template>
  <div>
    <transition name="fade">
      <pre-loader v-show="!loaded" @skip-preloader="loaded = true" />
    </transition>
    <full-page-template>
      <div class="p-home">
        <home-background :active-section="activeSection" />
        <app-navigator
          ref="navigator"
          class="app-navigator"
          :sections="fullPageOptions.anchors"
          @change-page="changePage"
          @move-section="moveSection"
        />
        <full-page id="fullpage" ref="fullpage" :options="fullPageOptions">
          <home-hero class="section" />
          <home-how ref="how" class="section" />
          <home-products class="section" />
          <home-about class="section" />
          <home-stats class="section" />
          <div class="section">
            <home-questions :class="{ active : activeSection === 'footer' }" @fullpage-rebuild="fullpageRebuild" />
            <app-footer />
          </div>
        </full-page>
      </div>
    </full-page-template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import HomeBackground from '@/components/home/inner/HomeBackground'
import PreLoader from '@/components/PreLoader'
import FullPageTemplate from '@/layouts/FullPageTemplate'
import AppNavigator from '@/components/common/AppNavigator'
import HomeHero from '@/components/home/HomeHero'
import HomeHow from '@/components/home/HomeHow'
import HomeProducts from '@/components/home/HomeProducts'
import HomeAbout from '@/components/home/HomeAbout'
import HomeStats from '@/components/home/HomeStats'
import HomeQuestions from '@/components/home/HomeQuestions'
import AppFooter from '@/components/common/AppFooter'

export default {
  name: 'Home',
  components: {
    HomeBackground,
    PreLoader,
    FullPageTemplate,
    AppNavigator,
    HomeHero,
    HomeHow,
    HomeProducts,
    HomeAbout,
    HomeStats,
    HomeQuestions,
    AppFooter
  },
  data () {
    return {
      loaded: false,
      activeSection: '',
      fullPageOptions: {
        anchors: ['home', 'how', 'products', 'about', 'stats', 'footer'],
        licenseKey: 'FFB008DD-B64342CE-9D967973-12897C85',
        onLeave: this.onLeave,
        normalScrollElements: '.scrollable-content',
        scrollOverflow: true,
        scrollOverflowOptions: {
          click: false
        },
        verticalCentered: false
      }
    }
  },
  mounted () {
    if (document.readyState === 'complete') {
      this.pageLoaded()
    } else {
      window.onload = () => {
        this.pageLoaded()
      }
    }
  },
  computed: {
    ...mapState(['drawerVisible']),
    fullpage () {
      return this.$refs.fullpage.api
    }
  },
  watch: {
    $route (to) {
      this.fullpage.moveTo(to.hash.slice(1))
    }
  },
  methods: {
    fullpageRebuild () {
      setTimeout(() => {
        this.fullpage.reBuild()
      }, 300);
    },
    pageLoaded () {
      this.fullpage.setAllowScrolling(false)
      setTimeout(() => {
        this.loaded = true
        setTimeout(() => {
          this.activeSection = this.fullpage.getActiveSection().anchor

          if (window.innerWidth > 1200) {
            this.fullpage.setAllowScrolling(true)
          }
          this.fullpage.reBuild()
        }, 500)
      }, 2500)
    },
    changePage(pageNumber) {
      this.fullpage.moveTo(pageNumber)
    },
    onLeave(origin, destination) {
      this.$refs.navigator.changePageScroll(origin.index, destination.index)
      this.activeSection = destination.anchor
      if (destination.index === 0) {
        this.fullpage.reBuild()
      }
      if (destination.index === 1) {
        this.$refs.how.toFirstSlide()
      }
    },
    moveSection (direction) {
      direction === 'up' ? this.fullpage.moveSectionUp() : this.fullpage.moveSectionDown()
    }
  },
}
</script>

<style lang="scss" scoped>
  .p-home {
    .section {
      overflow: hidden;
    }
    .app-navigator {
      z-index: 99;
      position: fixed;
      right: 40px;
      bottom: 20px;
      @include max-w-sm {
        right: 0;
        bottom: 24px;
      }
    }
  }
</style>