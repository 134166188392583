<template>
  <ul class="accordion">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'AppAccordion',
  props: {
    active: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      Accordion:  {
        count: 0,
        active: null
      }
    }
  },
  mounted () {
    if (this.active.length) {
      this.Accordion.active = Number(this.active)
    }
  },
  watch: {
    'Accordion.active' () {
      this.$emit('accordion-changed')
    }
  },
  provide () {
    return { Accordion: this.Accordion }
  }
}
</script>

<style lang="scss" scoped>
  .accordion {
    min-width: 300px;
  }
</style>