<template>
  <li class="accordion-item" :class="{ active : visible }">
    <div class="accordion-btn" @click="open">
      <p class="fs--medium title fw--bold">
        {{ title }}
      </p>
      <div class="circle-plus" :class="{ minus : visible }" />
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible" class="wrapper-content">
        <div class="content mb-m">
          <slot />
        </div>
        <slot name="other" />
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  name: 'AppAccordionItem',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  inject: ['Accordion'],
  data () {
    return {
      index: null
    }
  },
  computed: {
    visible () {
      return this.index == this.Accordion.active
    }
  },
  created() {
    this.index = this.Accordion.count++;
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
}
</script>

<style lang="scss" scoped>
  .accordion-item {
    &.active {
      .accordion-btn {
        border-bottom: none;
      }
    }
    .accordion-btn {
      cursor: pointer;
      padding: 24px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba($gray, .2);
      border-radius: 1px;
      transition: .3s;
      &:last-child {
        border: none;
      }
      .title {
        position: relative;
        padding-left: 20px;
        &::before {
          content: '';
          width: 8px;
          height: 8px;
          background: $gray;
          position: absolute;
          top: calc(50% - 4px);
          left: 0;
          border-radius: 50%;
        }
      }
    }
    .content {
      padding: 16px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
      border-radius: $border-radius-large;
    }
  }
  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }
</style>
