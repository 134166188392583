<template>
  <div class="speech-balloon" :class="position">
    {{ content }}
    <div class="angle" />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .speech-balloon {
    padding: 16px;
    background: $white;
    position: absolute;
    border-radius: $border-radius-base;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.02)) drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.08));
    &.bottom-right {
      right: -100px;
      bottom: -75px;
      .angle {
        top: -10px;
      }
      @include max-w-lg {
        .angle {
          left: 40%;
        }
      }
      @include max-w-xs {
        right: -50px;
      }
    }
    &.bottom-left {
      left: -100px;
      bottom: -75px;
      .angle {
        top: -10px;
        right: 50px;
        left: auto;
      }
      @include max-w-lg {
        .angle {
          right: 40%;
        }
      }
      @include max-w-xs {
        left: -50px;
      }
    }
    &.top-right {
      right: -100px;
      top: -100px;
      @include max-w-lg {
        .angle {
          left: 40%;
        }
      }
      @include max-w-xs {
        right: -50px;
      }
    }
    &.top-left {
      left: -100px;
      top: -100px;
      .angle {
        right: 50px;
        left: auto;
      }
      @include max-w-lg {
        .angle {
          right: 40%;
        }
      }
      @include max-w-xs {
        left: -50px;
      }
    }
    .angle {
      position: absolute;
      bottom: -10px;
      left: 50px;
      width: 20px;
      height: 20px;
      background: $white;
      transform: rotate(45deg);
    }
  }
</style>
